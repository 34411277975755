import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';

import styles from './hero.module.scss';

import illustration from './images/illustration.svg';

const cx = classNames.bind(styles);

const Hero = ({ title, description }) => (
  <section className={cx('wrapper')}>
    <div className={cx('container', 'inner')}>
      <div className={cx('left')}>
        <Heading className={cx('title')} size="xxl">
          {title}
        </Heading>
        <p className={cx('description')}>{description}</p>
      </div>
      <div className={cx('right')}>
        <img src={illustration} alt="" width={375} height={360} />
      </div>
    </div>
  </section>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Hero;
